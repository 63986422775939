<template>
  <p class="path" @click="popup" :style="{'width': `${windowWidth}px`}" v-if="route.name !== 'Home' && route.name !== 'Help' && route.name !== 'Select'">
    <span class="too-long" v-if="tooLong">...</span>
    <span ref="breadcrumbPath" :style="[tooLong ? { 'float': 'right' } : { 'float': 'left' }]"> {{ breadcrumbs }} </span>
  </p>
</template>

<script setup>
import {ref, onMounted, onUpdated, inject} from "vue";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();

let windowWidth =  ref(window.innerWidth);

const breadcrumbPath = ref(null);
const store = useStore();
const apiPath = inject('apiPath');

getBreadcrumbs();

let tooLong = ref(false);
function adjustBreadcrumbs() {
  if (breadcrumbPath.value !== null) {
    let breadcrumbWidth = breadcrumbPath.value.offsetWidth
    tooLong.value = windowWidth.value < (breadcrumbWidth + 20);
  }
}


function popup() {
  store.state.breadcrumbPopup = true;
}

let breadcrumbs = ref('');
async function getBreadcrumbs() {
  if (route.name === undefined || route.params === undefined || route.params.id === undefined) {
    breadcrumbs.value = '';
    return;
  }

  let routeName = '';
  switch (route.name.toLowerCase()) {
    case "machine":
      routeName = 'location';
      break;
    case "section":
      routeName = 'section';
      break;
    case "article":
      routeName = 'article';
      break;
  }

  let url = `entity/${routeName}/breadcrumbs`;

  let formData = new FormData();
  formData.append('token', store.state.usertoken);
  formData.append('id', route.params.id);

  fetch(`${apiPath}` + url, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json()).then((response) => {
    if (response.result) {
      let titles = [];
      response.result.forEach((element)=>{
        titles.push(element['title']);
      });
      breadcrumbs.value = titles.join(" / ");
    } else {
      console.log('Looks like there was a problem: \n', response.error);
      if (response.error === 'Wrong token') {
        window.location.reload();
      }
      breadcrumbs.value = '';
    }
  }).catch((error) => {
    console.log('Looks like there was a problem: \n', error);
    breadcrumbs.value = '';
  });
}

onMounted(() => {
  adjustBreadcrumbs();
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
    adjustBreadcrumbs();
  });
});

onUpdated(() => {
    adjustBreadcrumbs();
});

</script>

<style lang="scss">
.too-long{
  position: absolute;
  left: 0;
  height: inherit;
  padding: 0 3px 0 0;
  text-indent: 8px;
  background-color: #ececec;
  direction: ltr;
}
.path{
  color: #787878;
  background-color: #ececec;
  margin: 0;
  display: block;
  padding-left: 32px;
  padding-right: 16px;
  height: 36px;
  line-height: 36px;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
  // direction: rtl;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   padding-left: 100px;
  //   height: 80px;
  //   span{
  //     height: 80px;
  //     line-height: 80px;
  //     font-size: 36px;
  //   }
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    padding-left: 50px;
    height: 46px;
    span{
      height: 46px;
      line-height: 46px;
    }
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    padding-left: 40px;
    height: 44px;
    span{
      height: 44px;
      line-height: 44px;
    }
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    padding-left: 30px;
    height: 43px;
    span{
      height: 43px;
      line-height: 43px;
    }
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    padding-left: 15px;
    height: 41px;
    span{
      height: 41px;
      line-height: 41px;
    }
  }
}
@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
  and (min-height: 961px) and (orientation: portrait){
  .path{
    top: 0;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 500px) and (orientation: landscape) {
  .path{
    top: 0;
    float: right;
    span{
      float: right;
      overflow: hidden;
    }
  }
}
</style>
